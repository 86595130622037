<!-- 注册 -->
<template>
  <div style="background-color: #f2f2f2">
    <Header />
    <Sidebar />
    <div class="sign-box">
      <div class="sign-form-box register-box clearfix">
        <div class="register-title clearfix">
          <h2>
            <span class="inline-block icon-reg-mode icon-reg-phone vam"></span
            ><span class="vam">账号密码注册</span>
          </h2>
          <h2 class="hide">
            <span class="inline-block icon-reg-mode icon-reg-wx vam"></span
            ><span class="vam">微信快速注册</span>
            <span class="reg-mode-wrapper inline-block"
              >切换成<span class="reg-mode-text">账号密码注册></span></span
            >
          </h2>
          <span class="go-login"
            >已经拥有账号？
            <router-link to="/login">账号登录</router-link>
          </span>
        </div>
        <div class="register-space clearfix"></div>
        <div id="wechat-register" class="wx-qrcode-wraper tac hide">
          <div class="wx-qrcode-content">
            <div
              style="width: 240px; height: 240px"
              id="wx-qrcode-image"
              class="wx-qrcode-image"
            >
              <!-- <img class="vam" src="../template/Home/Zkeys/PC/Static/statics/css/front/img/login-qrcode.png" alt=""/> -->
            </div>
            <div class="wx-qrcode-title">请使用微信扫描二维码</div>
          </div>
          <h3 class="color-gray">微信扫码后即可进入注册流程</h3>
        </div>
        <div id="account-register" class="register-main">
          <form
            action="javascript:;"
            name="register"
            id="register"
            method="post"
            onsubmit="return false"
          >
            <div class="register-item-box">
              <div class="register-item">
                <label
                  >手机号码<span>*</span
                  ><input
                    type="text"
                    name="mobile"
                    id="mobile"
                    maxlength="11"
                    placeholder="请输入手机号码"
                    autofocus=""
                    v-model="tel"
                /></label>
              </div>
              <div id="mobileTip"></div>
            </div>
            <div class="register-item-box">
              <div class="register-item">
                <label
                  >短信验证码<span>*</span
                  ><input
                    type="text"
                    name="mobilecode"
                    id="mobilecode"
                    maxlength="6"
                    placeholder="请输入短信验证码"
                    v-model="code"
                  />
                  <input
                    type="button"
                    class="send-captcha"
                    v-show="show"
                    value="发送短信验证"
                    @click="sendCode"
                  />
                  <input
                    type="button"
                    class="send-captcha"
                    v-show="!show"
                    :value="count"
                  />
                </label>
              </div>
              <div id="mobilecodeTip"></div>
            </div>

            <div class="register-item-box">
              <div class="register-item">
                <label
                  >设置密码<span>*</span
                  ><input
                    type="password"
                    name="password"
                    id="password"
                    maxlength="20"
                    placeholder="请输入密码"
                    v-model="password"
                /></label>
              </div>
              <div id="passwordTip"></div>
            </div>
            <div class="register-item-box">
              <div class="register-item">
                <label
                  >确认密码<span>*</span
                  ><input
                    type="password"
                    name="password2"
                    id="password2"
                    maxlength="20"
                    placeholder="请再次填写密码"
                    v-model="password2"
                /></label>
              </div>
              <div id="password2Tip"></div>
            </div>
            <div class="login-options">
              <label
                for="agreementCheckbox"
                id="checkboxLabel"
                class="idsave-check"
              >
                <!-- <span class="all-sign-sprite checkbox-icon" id="checkboxTrigger"></span> -->
                <el-checkbox v-model="checked"></el-checkbox>
                <input
                  type="checkbox"
                  id="agreementCheckbox"
                  name="agreement"
                  value="1"
                />
                我已阅读并同意
                <router-link :to="{ name: 'law' }"
                  ><a>《企业服务平台网站服务协议》</a></router-link
                >
              </label>
            </div>
            <input
              id="submitBtn"
              type="submit"
              value="注册账号"
              class="reg-submit"
              @click="Register"
            />
          </form>
        </div>
      </div>
    </div>
    <div id="captchaCheck" class="captcha-box hide">
      <table>
        <tr>
          <th>验证码：</th>
          <td>
            <div>
              <input
                type="text"
                name="captcha"
                id="captcha"
                class="input"
                style="width: 120px"
              />&nbsp;&nbsp;<img class="show-captcha" src="" />
            </div>
          </td>
          <td></td>
        </tr>
        <tr>
          <th></th>
          <td>
            <div class="tip">
              <div id="captchaTip"></div>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="hide">
      <!---->
      <span id="minPwd">8</span>
      <span id="maxPwd">20</span>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/Header/index.vue";
import Footer from "../../components/Footer/index.vue";
import Sidebar from "../../components/Sidebar/index.vue";
import { getCode, getRegister } from "@/api/all.js";
import { Message, MessageBox } from "element-ui";
import { setToken } from "@/utils/auth.js";
export default {
  components: { Header, Footer, Sidebar },
  data() {
    return {
      checked: false,
      count: "",
      timer: null,
      show: true,
      tel: "",
      code: "",
      password: "",
      password2: "",
      qq: "",
    };
  },
  created() {},
  watch: {
    tel: function (newQuestion) {
      console.log(newQuestion);
    },
    code: function (newQuestion) {
      console.log(newQuestion);
    },
  },
  methods: {
    sendCode() {
      getCode({ phone: this.tel }).then((res) => {
        if (res.code == 200) {
          // 自定义时间 TIME_COUNT 默认 60
          const TIME_COUNT = 60;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    Register() {
      if (this.checked == true) {
        if (this.password == this.password2) {
          getRegister({
            account: this.tel,
            code: this.code,
            password: this.password,
          }).then((res) => {
            console.log(res, "注册");
            if (res.code == 200) {
              this.$message({
                message: "注册成功",
                type: "success",
              });
              setToken(res.data.token);
              localStorage.setItem("username", res.data.people.name);
              localStorage.setItem("phone", res.data.people.phone);
              // 改变登录状态
              this.$store.commit("changeState");
              localStorage.setItem("islogin", true);
              this.$router.push({ path: "/" });
            } else {
              this.$message.error("注册失败");
            }
          });
        } else {
          this.$message.error("两次输入密码不一致");
        }
      } else {
        this.$message.error("请同意《企业服务平台网站服务协议》");
      }
    },
  },
};
</script>

<style scoped>
.top-header {
  position: relative;
}

.sign-box {
  margin-top: 0;
}

#captchaTwo {
  width: 400px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 45px;
}

#captchaTwo label {
  vertical-align: top;
  display: inline-block;
  width: 80px;
  text-align: right;
}

.register-item label .geetest_radar_tip_content {
  border: none;
  color: #18a452;
}

#captchaTwo .geetest_success_radar_tip_timeinfo {
  border: none;
}

#captchaTwo .geetest_success_radar_tip_content {
  border: none;
}

#text {
  height: 42px;
  width: 400px;
  text-align: center;
  border-radius: 2px;
  background-color: #f3f3f3;
  color: #bbbbbb;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 42px;
}

#wait {
  display: none;
  height: 42px;
  width: 400px;
  text-align: center;
  border-radius: 2px;
  background-color: #f3f3f3;
}

.loading {
  margin: auto;
  width: 70px;
  height: 20px;
}

.loading-dot {
  float: left;
  width: 8px;
  height: 8px;
  margin: 18px 4px;
  background: #ccc;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  opacity: 0;

  -webkit-box-shadow: 0 0 2px black;
  -moz-box-shadow: 0 0 2px black;
  -ms-box-shadow: 0 0 2px black;
  -o-box-shadow: 0 0 2px black;
  box-shadow: 0 0 2px black;

  -webkit-animation: loadingFade 1s infinite;
  -moz-animation: loadingFade 1s infinite;
  animation: loadingFade 1s infinite;
}

.loading-dot:nth-child(1) {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
  animation-delay: 0s;
}

.loading-dot:nth-child(2) {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

.loading-dot:nth-child(3) {
  -webkit-animation-delay: 0.2s;
  -moz-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

.loading-dot:nth-child(4) {
  -webkit-animation-delay: 0.3s;
  -moz-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

@-webkit-keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

@-moz-keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

@import "../../assets/static/template/Home/Zkeys/PC/Static/css/module/register/register.css";
</style>
