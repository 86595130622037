<!-- 脚部组件 -->
<template>
  <div>
    <div v-if="isLogin" class="footer-banner clearfix">
      <div class="container">
        <div class="footer-title">加入我们，立即开启您的云服务之旅！</div>
        <router-link class="footer-reg" to="/register">免费注册</router-link>
      </div>
    </div>
    <div class="footer clearfix">
      <div class="container">
        <div class="clearfix">
          <ul class="foot-menu">
            <li>
              <h4>企业服务</h4>
              <hr class="hr" />
              <div style="float: left; margin-right: 50px">
                <router-link :to="{ name: 'buy', query: { id: 2 } }"
                  >百度推广</router-link
                >
                <router-link :to="{ name: 'buy', query: { id: 6 } }"
                  >Ai建站</router-link
                >
                <router-link :to="{ name: 'buy', query: { id: 47 } }"
                  >天猫入驻</router-link
                >
                <a @click="change(txid)">腾讯云企业服务</a>
                <a @click="change(videoid)">短视频服务</a>
              </div>
            </li>
            <!--            <li>-->
            <!--              <h4>帮助与支持</h4>-->
            <!--              <hr class="hr" />-->
            <!--&lt;!&ndash;			      <router-link to="/help">产品文档</router-link>&ndash;&gt;-->
            <!--			      <router-link to="/about/company">公司简介</router-link>-->
            <!--              &lt;!&ndash;<a href="/domain/whois.html"  >Whois查询</a>-->
            <!--              <a href="user/workorder/add.html">控制面板</a>-->
            <!--              <a href="/user/icp/"  >备案服务</a>&ndash;&gt;-->
            <!--              &lt;!&ndash; <a href="user/workorder/add.html">工单服务</a> &ndash;&gt;-->
            <!--            </li>-->
            <li>
              <h4>其他链接</h4>
              <hr class="hr" />
              <router-link to="/about/company">公司简介</router-link>
              <router-link to="/about/contact">联系我们</router-link>
              <router-link to="/about/news">新闻公告</router-link>
              <router-link to="/about/links">友情链接</router-link>
              <router-link to="/agreement">服务协议</router-link>
            </li>
            <li class="aboutout">
              <h4>联系我们</h4>
              <hr class="hr" />
              <div>
                <span class="text-godbar">地址</span><br />
                <span>无锡市梁溪区联东U谷33号泛亚大厦</span>
              </div>
              <div>
                <span class="text-godbar">电话&传真</span><br />
                <span>0510-68860000</span>
              </div>
              <div>
                <span class="text-godbar">E-MAIL</span><br />
                <span>online@panasia.com</span>
              </div>
            </li>
          </ul>
        </div>

        <div class="foot-link">
          <a href="https://www.baidu.com/" target="_blank" title="百度">百度</a>
        </div>
      </div>
    </div>
    <div class="foot-copyright">
      <p>
        Copyright © 2013-2024 i360.cn. All Rights Reserved. 无锡市泛亚资讯网络有限公司
        版权所有　泛亚企业服务平台　<a target="_blank" href="http://beian.miit.gov.cn">苏ICP备2022035803号-1</a>
      </p>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLogin: true,
      txid: "1631943408415354882",
      videoid: "1632302569770659842",
    };
  },
  created() {
    if (localStorage.getItem("username") != undefined) {
      this.isLogin = false;
    }
  },
  methods: {
    change(id) {
      console.log(id);
      this.$router.push({ name: "buy", query: { id } });
    },
  },
};
</script>

<style scoped></style>
