<!-- 侧边栏 -->
<template>
  <div class="float-consult border-box">
    <ul>
      <li>
        <div class="suspension-item qq">
          QQ咨询
          <div class="suspension-detail-outer">
            <div class="suspension-detail">
              <div class="suspension-detail-title">请选择客服进行咨询</div>
              <ul class="suspension-detail-list clearfix">
                <li class="icon-consult">
                  <a
                    href="tencent://message/?uin=3461791957&Site=&Menu=yes"
                    target="_blank"
                    >3461791957</a
                  >
                  <!--              <a href="http://wpa.qq.com/msgrd?v=3&uin=3461791957&site=qq&menu=yes" target="_blank">3461791957</a>-->
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <li>
        <div class="suspension-item tel" href="">
          电话咨询
          <div class="suspension-detail-outer">
            <div class="suspension-detail">
              <div class="suspension-detail-title">请选择客服进行咨询</div>
              <ul class="suspension-detail-list clearfix">
                <li class="icon-small-tel">
                  <a>0510-68860000</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <!--<li>
			<a href="/user/icp/" target="/user" class="cloumn cs4">备案</a>
		</li>-->
      <!-- <li>
        <a href="" target="/user" class="cloumn cs5">工单</a >
      </li> -->
      <li class="back-top">
        <p class="cloumn cs8 top" id="btnBox" @click="goBack">TOP</p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    // 回到顶部操作
    goBack() {
      document.documentElement.scrollTop = 0;
    },
    //监听回到顶部按钮距浏览器顶部的距离
    scrollToTop() {
      // 获取dom元素id
      let topBtn = document.getElementById("btnBox");
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 判断距离顶部多少显示回到顶部图标
      if (scrollTop > 500) {
        topBtn.style.display = "block";
      } else if (scrollTop < 500) {
        topBtn.style.display = "none";
      }
    },
  },
  mounted() {
    // 侦听事件并处理相应函数
    window.addEventListener("scroll", this.scrollToTop);
  },
  //离开该页面需要移除这个监听的事件，不然会报错;
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style scoped>
.back-top {
  transition: 0.3s all linear;
  visibility: visible;
}
</style>
